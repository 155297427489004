import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { Md5 } from "ts-md5";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";


@Injectable({
  providedIn: "root",
})
export class WechatService {

  constructor(
    public http: HttpService,
    public router: Router,
    private local: StorageService,
  ) {}

  /**
   * 配置微信分享
   */
  initWxShareConfig(title, desc, link, img) {
    if (!(window as any).wx) {
      return;
    }
    // alert('link1:'+link);
    // 判断ios系统
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS &&  window.sessionStorage.getItem("firstEntryUrl")!=link) {
      link = window.sessionStorage.getItem("firstEntryUrl");
    }
    // alert('link2:'+link);
    this.config(title, desc, link, img);
  }

  /**
   * 微信数据实际配置方法
   */
  config(title, desc, link, img) {
    const wx = (window as any).wx;
    let signature = Md5.hashStr(
      "CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|" +
        new Date().getTime().toString().substr(0, 10) +
        "|getsignpackage"
    );
    var api = "user/user/getSignPackage";
    var body = {
      timestamp: new Date().getTime().toString().substr(0, 10),
      signature: signature,
      url: link,
    };
    // alert(body.url);
    this.http.doPost(api, body, (result) => {
      if (result.code == "200") {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId,
          timestamp: result.data.timestamp,
          nonceStr: result.data.nonceStr,
          signature: result.data.signature,
          jsApiList: [
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
            'onMenuShareQQ',
            'onMenuShareQZone',
          ], // 必填，需要使用的JS接口列表
        });
      }
    });

    wx.ready(function () {
      wx.onMenuShareTimeline({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success:function() {
        }
      });
      wx.onMenuShareAppMessage({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success:function() {
        }
      });
      wx.onMenuShareQQ({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success:function() {
        }
      });
      wx.onMenuShareQZone({
        title: title, // 分享标题
        desc: desc, // 分享描述
        link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img, // 分享图标
        success:function() {
        }
      });
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(JSON.stringify(res));
    });
    wx.checkJsApi({
      jsApiList: ['onMenuShareAppMessage','onMenuShareTimeline'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success: function(res) {
        console.log(JSON.stringify(res))
      }
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { IconsProviderModule } from './icons-provider.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { VersionService } from './service/version.service';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { Header2Component } from './component/header2/header2.component';
import { Footer2Component } from './component/footer2/footer2.component';
import { MainComponent } from './component/main/main.component';
import { LiveDetailComponent } from './pages/live/live-detail/live-detail.component';


import { Main_enComponent } from './component/main_en/main_en.component';
import { Footer_enComponent } from './component/footer_en/footer_en.component';
import { Header_enComponent } from './component/header_en/header_en.component';
import { Footer2_enComponent } from './component/footer2_en/footer2_en.component';
import { Header2_enComponent } from './component/header2_en/header2_en.component';


import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { QRCodeModule } from 'angular2-qrcode';


registerLocaleData(zh);

@NgModule({
  declarations: [	
    AppComponent,
    HeaderComponent,
    FooterComponent,
    Header2Component,
    Footer2Component,
    MainComponent,
    Main_enComponent,
    Footer_enComponent,
    Header_enComponent,
    Footer2_enComponent,
    Header2_enComponent,
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzAffixModule,
    NzCarouselModule,
    NzModalModule,
    NzMessageModule,
    NzDropDownModule,
    // NzFormModule,
    // NzSelectModule,
    // NzInputModule,
    // NzUploadModule,
    // NzRadioModule,
    // NzTableModule,
    // NzProgressModule,
    NzBackTopModule,
    // NzIconModule,
    // NzSpinModule,
    // QRCodeModule,
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    {provide: VersionService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<nz-affix [nzOffsetTop]="0">
  <div class="bg">
  <div class="top">
    <div class="logo">
      <a routerLink="/en"><img src="../../../assets/images/logo-pg-conference.jpg"></a>
    </div>
      <div class="user" *ngIf="userinfo==null" style="margin-right: 20px;">
          <i class="iconfont icon-yonghutouxiang" routerLink="/login_en"></i>
          <!-- <span><a routerLink="register_en">Register</a></span>
          <span style="margin: 0 10px;">|</span>
          <span><a routerLink="/login_en">Sign in</a></span> -->
      </div>
      <div class="user" *ngIf="userinfo != null">
        <!-- <span class="title">PostgresConf.CN & PGConf.Asia 2021</span> -->
        <span>{{userinfo.name_en}}</span>
        <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="padding-right: 15px;">
            <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
            <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
            <i class="iconfont icon-xiala xiala"></i>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu style="font-size:14px;margin-top: -15px;">
                <li class="addli" nz-menu-item [routerLink]="['/signup_en']">
                    <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>registration
                </li>
                <li class="addli" nz-menu-item [routerLink]="['/mydata_en']">
                    <i class="iconfont icon-baoming" style="font-size: 20px;"></i>complete material
                </li>
                <!-- <li class="addli" nz-menu-item [routerLink]="['/theme_en']">
                    <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>submit speech topic
                </li> -->
                <li class="addli" nz-menu-item [routerLink]="['/submittedTheme_en']">
                    <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>my presentation
                </li>
                <li class="addli" nz-menu-item (click)="logout()">
                    <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>sign out
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
  </div>
  </div>
  </nz-affix>

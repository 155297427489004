<div class="body">
  <nz-affix >
    <div class="header">
      <div class="top">
        <div>
          <i class="iconfont icon-caidan" style="font-size: 30px;padding-left: 10px;color: #ffffff;" nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomLeft'"></i>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable class="menus">
            <li nz-menu-item>Home</li>
            <li nz-menu-item routerLink="/agenda_en">Schedule</li>
            <li nz-menu-item routerLink="/sponsor_en">Sponsor</li>
            <li nz-menu-item routerLink="/organizer_en">Organizer</li>
            <!-- <li *ngIf="!isThemeEnd" nz-menu-item (click)="goUrl('/theme_en')">Submit speech</li> -->
            <li nz-menu-item routerLink="/cooperate_en">Cooperate & Recruit</li>
            <li nz-menu-item routerLink="/liveEntry_en">Live</li>
            <li nz-menu-item routerLink="/previous_en">Previous</li>
            <li nz-menu-item routerLink="/">中文</li>
        </ul>   
        </nz-dropdown-menu>
        <!-- <div class="menus">
          <span class="active">Home</span>
          <span routerLink="/agenda_en">Schedule</span>
          <span routerLink="/sponsor_en">Sponsor</span>
          <span (click)="goUrl('/theme_en')">Submit speech</span>
          <span routerLink="/cooperate_en">Cooperate & Recruit</span>
          <span routerLink="/liveEntry_en">Live</span>
          <span routerLink="/previous_en">Previous</span>
          <span routerLink="/">中文</span>
        </div> -->
        <div class="user" *ngIf="userinfo == null" style="padding-right: 15px;">
          <i class="iconfont icon-yonghutouxiang" style="margin-right: 8px;font-size: 20px;color: #FFFFFF;"></i>
          <span><a routerLink="/register_en">Register</a></span>
          <span style="margin: 0 8px;">|</span>
          <span><a routerLink="/login_en">Sign in</a></span>
        </div>
        <div class="user" *ngIf="userinfo != null">
          <span>{{userinfo.name_en}}</span>
          <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="padding-right: 15px;">
            <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
            <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
            <i class="iconfont icon-xiala xiala"></i>
          </div>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu
              style="font-size:14px;margin-top: -10px;">
              <li class="addli" nz-menu-item [routerLink]="['/signup_en']">
                <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>registration
              </li>
              <li class="addli" nz-menu-item [routerLink]="['/mydata_en']">
                <i class="iconfont icon-baoming" style="font-size: 20px;"></i>complete material
              </li>
              <!-- <li class="addli" nz-menu-item [routerLink]="['/theme_en']" *ngIf="!isThemeEnd">
                <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>submit speech topic
              </li> -->
              <li class="addli" nz-menu-item [routerLink]="['/submittedTheme_en']">
                <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>my presentation
              </li>
              <li class="addli" nz-menu-item (click)="logout()">
                <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>sign out
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        
      </div>
    </div>
  </nz-affix>
  <div style="margin: 0 auto;padding: 0 20px;">
    <div style="text-align: center;">
      <img src="../../../assets/images/title-conference-en.jpg" style="width: 75vw;">
      <div class="sign-banner">
        <button class="sign-btn" (click)="goUrl('/signup_en')">sign up</button>
        <!-- <div class="sign-text">
          <i class="iconfont icon-jiantouyou you-icon"></i>
          <i class="iconfont icon-jiantouyou you-icon" style="margin-right: 10px;"></i>registration channel has been opened
        </div> -->
      </div>
      <div class="des">
        <div class="des-time">12.14-12.17 / ONLINE</div>
        <div style="font-size: 16px;letter-spacing: 1px;">Born in the world / Ecological Value of PG in China, and Asia</div>
        <div><img src="../../../assets/images/logo-conference.jpg"></div>
      </div>
      <div class="item">
        <div class="item-title">
          <span>ABOUT</span>
          <!-- <span class="test">ABOUT</span> -->
        </div>
        <!-- <div class="item-des">
          智造亚洲未来，共塑产业协同价值
        </div> -->
        <div class="about-content">
          <div class="content">
            <div><span style="font-weight: bold;">PostgreSQL (PG)</span>is the most advanced open source relational database in the world, ranking the fourth in the world, showing a rapid upward trend in recent years.</div>
            <div style="margin-top: 20px;">In the 1980s, Michael Stonebraker, a professor at the University of California, Berkeley, developed Postgres software package. In the following decades, it gradually evolved into a world-famous open source database development project.</div>
          </div>
          <div class="content">
            <div>
              Adhering to the concept of "global experts, online connection", we invite global masters, experts and scholars in the field of PostgreSQL to continue to gather together through the Internet, and will hold <span style="font-weight: bold;">“PostgresConf.CN & PGConf.Asia 2021(China Branch)"</span> from <span style="font-weight: bold;">December 14 to 17, 2021</span>.
            </div>
            <div style="margin-top: 20px;">
              Here, we sincerely invite colleagues from the industry and academia to join us in this grand event of PostgreSQL global technology exchange, and jointly compose a new chapter in the development and ecological construction of PostgreSQL.
            </div>
          </div>
        </div>
        <div class="about-circle">
          <div class="circle-item cir1">
            <div class="num">2000+</div>
            <div>Participants</div>
          </div>
          <div class="circle-item cir2">
            <div class="num">100+</div>
            <div>Speakers</div>
          </div>
          <div class="circle-item cir1">
            <div class="num">6</div>
            <div>Special Training</div>
          </div>
          <div class="circle-item cir2">
            <div class="num">4</div>
            <div>Days</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="item">
    <div class="item-title">
      <!-- <span>特邀嘉宾</span> -->
      <span>Previous Guests</span>
    </div>
    <div class="guest-swiper">
      <i class="iconfont icon-zuo" (click)="carousel.pre()" title="previous"></i>
      <nz-carousel class="swiper" [nzDots]="false" #carousel>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/1-Bruce Momjian.jpg">
            </div>
            <h1>Bruce Momjian</h1>
            <p>PostgreSQL Global Core Team. VP of EnterpriseDB U.S.A</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/2-Joshua D.Drake.jpg">
            </div>
            <h1>Joshua D.Drake</h1>
            <p>Chairman of PostgresConf.ORG, U.S.A. CEO of Command Prompt Inc.</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/3-Peter Zaitsev.jpg">
            </div>
            <h1>Peter Zaitsev</h1>
            <p>CEO of Percona</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/14-Tatsuo Ishii.jpg">
            </div>
            <h1>Tatsuo Ishii</h1>
            <p>Steering Committee countries. Co-Founder of JPUG. Author of pgpool. Branch Manager of SRA OSS, Inc. Japan</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/1-陆首群.jpg">
            </div>
            <h1>Shouqun Lu</h1>
            <p>Honorary Chairman of China OSS Promotion Union.Former executive deputy director of the Information Office of the State Council.</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/2-倪光南.jpg">
            </div>
            <h1>Guangnan Ni</h1>
            <p>Academician of Chinese Academy of Engineering</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/3-刘澎.jpg">
            </div>
            <h1>Liu Peng</h1>
            <p>OPU Vice chairman & Secretary General</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/4-彭智勇.jpg">
            </div>
            <h1>Zhiyong Peng</h1>
            <p>Deputy Director of the Database Committee of China Computer Federation</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/11-Oleg Bartunov.jpg">
            </div>
            <h1>Oleg Bartunov</h1>
            <p>Founder of Russia PostgresProfessional</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/19-Ibrar Ahmed.jpg">
            </div>
            <h1>Ibrar Ahmed</h1>
            <p>Author of《PostgreSQL 9X之巅》</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/13-Michael Mesks.jpg">
            </div>
            <h1>Michael Mesks</h1>
            <p>Instacluster CTO</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/18-Julyanto SUTANDANG.jpg">
            </div>
            <h1>Julyanto SUTANDANG</h1>
            <p>Technopreneur</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/5-丁治明.jpg">
            </div>
            <h1>Zhiming Ding</h1>
            <p>Researcher, Institute of Software, Chinese Academy of Sciences</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/6-胡雷钧.jpg">
            </div>
            <h1>Leijun Hu</h1>
            <p>Inspur Vice President</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/7-冯雷.jpg">
            </div>
            <h1>Feng Lei</h1>
            <p>General Manager of Pivotal China R&D Center</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/guest/8-苗健.jpg">
            </div>
            <h1>Miao Jian</h1>
            <p>Chairman of Highgo Software</p>
          </div>
        </div>
      </nz-carousel>
      <i class="iconfont icon-you" (click)="carousel.next()" title="next"></i>
    </div>
  </div>
  <div class="item">
    <div class="item-title">
      <!-- <span>会议日程</span> -->
      <span>Schedule</span>
    </div>
    <div class="agenda">
      <div class="agenda-left">
        <div class="graph"></div>
      </div>
      <div class="agenda-content">
        <div class="agenda-item" *ngFor="let meeting of meetingList;index as i">
          <div class="data">
            <div class="datatime">{{meeting.date}}</div>
            <div class="day">DAY-{{i+1}}</div>
          </div>
          <div style="display: flex;flex-wrap: wrap;width: 100%;">
            <div class="forum" *ngFor="let meet of meeting.specialshow">
              <div class="time">{{meet.start_time}}-{{meet.end_time}}</div>
              <div class="forum-content">{{meet.name_en}}</div>
            </div>
          </div>
        </div>
        <div class="agenda-des">*The final schedule is subject to the date of the meeting<br>The agenda time is Beijing Time(GMT+8)</div>
      </div>
      <div class="agenda-right">
        <div class="img"></div>
      </div>
    </div>
  </div>
  <div style="margin: 0 auto;padding: 0 20px;">
    <div>
      <div class="item">
        <div class="item-title">
          <!-- <span>合作</span> -->
          <span>COOPERATE</span>
        </div>
        <div style="text-align: left;">
          <div class="organize">Organizer</div>
          <div class="org-img">
            <img src="../../../assets/images/cooperation/1.png">
            <img src="../../../assets/images/cooperation/2.png">
          </div>
          <div class="organize">Co-Organizer</div>
          <div class="org-img" style="margin-bottom: 30px;">
            <img src="../../../assets/images/cooperation/3-en.png">
            <img src="../../../assets/images/cooperation/4-en.png">
            <!-- <img src="../../../assets/images/cooperation/5-en.png"> -->
            <img src="../../../assets/images/cooperation/6-en.png">
            <img src="../../../assets/images/cooperation/7-en.png">
            <img src="../../../assets/images/cooperation/8-en.png">
            <img src="../../../assets/images/cooperation/9-en.png">
            <img src="../../../assets/images/cooperation/10-en.png">
            <img src="../../../assets/images/cooperation/11-en.png">
            <img src="../../../assets/images/cooperation/12-en.png">
            <img src="../../../assets/images/cooperation/13-en.png">
            <img src="../../../assets/images/cooperation/14-en.png">
          </div>
          <div class="organize">Community partners</div>
          <div class="org-img">
            <a href="https://pgfans.cn/" target="_blank"><img src="../../../assets/images/pgfans.jpg"></a>
            <a href="https://www.oschina.net/" target="_blank"><img src="../../../assets/images/oschina.jpg"></a>
            <a href="https://cn.greenplum.org/" target="_blank"><img src="../../../assets/images/greenplum.jpg"></a>
            <a href="https://www.modb.pro/" target="_blank"><img src="../../../assets/images/modb.jpg"></a>
            <a href="http://mongoing.com/" target="_blank"><img src="../../../assets/images/mongo.png"></a>
            <a href="https://www.csdn.net/" target="_blank"><img src="../../../assets/images/logo-csdn.png"></a>
            <a href="https://segmentfault.com/" target="_blank"><img src="../../../assets/images/logo-sf.png"></a>
            <a href="http://www.itpub.net/" target="_blank"><img src="../../../assets/images/logo-IT168.png"></a>
            <a href="" target="_blank"><img src="../../../assets/images/logo-bg.png"></a>
            <a href="" target="_blank"><img src="../../../assets/images/logo-hdj.png"></a>
          </div>
          <div class="organize">Training partners</div>
          <div class="org-img">
            <a href="http://www.sandata.com.cn/" target="_blank"><img src="../../../assets/images/px/logo-px1.png"></a>
            <a href="https://www.pgedu.cn/pc/home" target="_blank"><img src="../../../assets/images/px/logo-px3.png"></a>
            <a href="https://www.yunbee.net/" target="_blank"><img src="../../../assets/images/px/logo-px4.png"></a>
            <a href="https://www.easthome.com/" target="_blank"><img src="../../../assets/images/px/logo-px2.png"></a>
            <a href="http://www.mchz.com.cn/" target="_blank"><img src="../../../assets/images/px/logo-px5.png"></a>
          </div>
        </div>
      </div>
      <div class="item" style="margin-bottom: 40px;">
        <div class="item-title">
          <!-- <span>演讲征集</span> -->
          <span>CFP</span>
        </div>
        <div>The speech collection channel has been opened.<br><br></div>
        <div style="line-height: 22px;font-size: 14px">
          We provide each guest with a 30-minute sharing period (25 minutes of speech + 5 minutes of Q&A).The direction of the speech can be: kernel development, application practice, operation and maintenance management, data migration, distributed, high availability, security control, optimization, cloud database, open source upstream and downstream ecology, etc.<br><br>
          Please submit your speech topic first. The deadline for topic submission is <span class="stress">November 29, 2021</span><!-- , 
          with <span class="stress">{{timeDay1}}</span> days remaining. The organizing committee will complete the speech review before November 30. 
          If you pass the review at that time, please start preparing the speech content, download and use the unified <span class="stress">PPT template</span> of the conference  -->.
          The deadline for PPT submission is <span class="stress">December 10, 2021</span>, with <span class="stress">{{timeDay}}</span> days remaining.<br><br>
        </div>
        <div>
          <!-- <button *ngIf="!isThemeEnd" class="b-btn submit-btn" (click)="goUrl('/theme_en')">submit speech</button> -->
          <a *ngIf="!isEnd" href="../../../assets/PostgresConf.CN 2022 PPT模板.pptx" download="PostgresConf.CN 2022 PPT模板.pptx"><button class="y-btn down-btn">download PPT template</button></a>   
        </div>
      </div>
    </div>
  </div>
</div>
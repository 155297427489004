<nz-affix [nzOffsetTop]="0">
  <div class="bg">
      <div class="top">
        <div>
          <i class="iconfont icon-caidan" style="font-size: 30px;padding-left: 10px;color: #333333;" nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomLeft'"></i>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable class="menus">
              <li nz-menu-item routerLink="/en">Home</li>
              <li nz-menu-item routerLink="/agenda_en">Schedule</li>
              <li nz-menu-item routerLink="/sponsor_en">Sponsor</li>
              <li nz-menu-item routerLink="/organizer_en">Organizer</li>
              <!-- <li *ngIf="!isThemeEnd" nz-menu-item (click)="navActive('/theme_en')">Submit speech</li> -->
              <li nz-menu-item routerLink="/cooperate_en">Cooperate & Recruit</li>
              <li nz-menu-item routerLink="/liveEntry_en">Live</li>
              <li nz-menu-item routerLink="/previous_en">Previous</li>
              <li nz-menu-item routerLink="/">中文</li>
            </ul>    
        </nz-dropdown-menu>
          <div class="user" *ngIf="userinfo == null" style="padding-right:15px;">
              <i class="iconfont icon-yonghutouxiang" style="margin-right: 8px;font-size: 20px;"></i>
              <span><a routerLink="/register_en">Register</a></span>
              <span style="margin: 0 8px;">|</span>
              <span><a routerLink="/login_en">Sign in</a></span>
          </div>
          <div class="user" *ngIf="userinfo != null">
              <span>{{userinfo.name_en}}</span>
              <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="padding-right:15px;">
                <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
                <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
                <i class="iconfont icon-xiala xiala"></i>
              </div>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu style="font-size:14px;margin-top: -10px;">
                <li class="addli" nz-menu-item [routerLink]="['/signup_en']">
                  <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>registration
                </li>
                <li class="addli" nz-menu-item [routerLink]="['/mydata_en']">
                  <i class="iconfont icon-baoming" style="font-size: 20px;"></i>complete material
                </li>
                <!-- <li class="addli" nz-menu-item [routerLink]="['/theme_en']" *ngIf="!isThemeEnd">
                  <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>submit speech topic
                </li> -->
                <li class="addli" nz-menu-item [routerLink]="['/submittedTheme_en']">
                  <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>my presentation
                </li>
                <li class="addli" nz-menu-item (click)="logout()">
                  <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>sign out
                </li>
              </ul>
              </nz-dropdown-menu>
          </div>
      </div>
  </div>
</nz-affix>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './component/main/main.component';
import { LiveDetailComponent } from './pages/live/live-detail/live-detail.component';


import { Main_enComponent } from './component/main_en/main_en.component';

const routes: Routes = [
  // { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) }
  { path:'',component:MainComponent },
  { path:'agenda',loadChildren: () => import('./pages/agenda/agenda.module').then(m => m.AgendaModule) },
  { path:'accommodation',loadChildren: () => import('./pages/accommodation/accommodation.module').then(m => m.AccommodationModule)},
  { path:'guest',loadChildren: () => import('./pages/guest/guest.module').then(m => m.GuestModule)  },
  { path:'sponsor',loadChildren: () => import('./pages/sponsor/sponsor.module').then(m => m.SponsorModule) },
  { path:'cooperate',loadChildren: () => import('./pages/cooperate/cooperate.module').then(m => m.CooperateModule) },
  { path:'liveEntry',loadChildren: () => import('./pages/live/live-entry/live-entry.module').then(m => m.LiveEntryModule) },
  { path:'organizer',loadChildren: () => import('./pages/organizer/organizer.module').then(m => m.OrganizerModule) },
  { path:'rules',loadChildren: () => import('./pages/rules/rules.module').then(m => m.RulesModule) },
  { path:'feedback',loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackModule) },
  { path:'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  { path:'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path:'signup',loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule) },
  { path:'signupSuccess', loadChildren: () => import('./pages/signup/signup-success/signup-success.module').then(m => m.SignupSuccessModule)  },
  { path:'theme', loadChildren: () => import('./pages/theme/theme.module').then(m => m.ThemeModule)},
  { path:'submittedTheme', loadChildren: () => import('./pages/submitted-theme/submitted-theme.module').then(m => m.SubmittedThemeModule) },
  { path:'theme-edit',loadChildren: () => import('./pages/theme/theme-edit/theme-edit.module').then(m => m.ThemeEditModule) },
  { path:'mydata', loadChildren: () => import('./pages/mydata/mydata.module').then(m => m.MydataModule) },
  { path:'speech/:id',loadChildren: () => import('./pages/speech/speech.module').then(m => m.SpeechModule) },
  { path:'live/:id/:name', loadChildren: () => import('./pages/live/live-detail/live-detail.module').then(m => m.LiveDetailModule) },
  { path:'forget', loadChildren: () => import('./pages/forget/forget.module').then(m => m.ForgetModule) },
  { path:'previous',loadChildren: () => import('./pages/previous/previous.module').then(m => m.PreviousModule) },
  { path:'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
  { path:'en', component:Main_enComponent },
  { path:'sponsor_en',loadChildren: () => import('./pages_en/sponsor/sponsor.module').then(m => m.SponsorModule) },
  { path:'organizer_en',loadChildren: () => import('./pages_en/organizer/organizer.module').then(m => m.OrganizerModule) },
  { path:'cooperate_en',loadChildren: () => import('./pages_en/cooperate/cooperate.module').then(m => m.CooperateModule) },
  { path:'agenda_en',loadChildren: () => import('./pages_en/agenda/agenda.module').then(m => m.AgendaModule) },
  { path:'liveEntry_en',loadChildren: () => import('./pages_en/live/live-entry/live-entry.module').then(m => m.LiveEntryModule) },
  { path:'previous_en',loadChildren: () => import('./pages_en/previous/previous.module').then(m => m.PreviousModule) },
  { path:'feedback_en',loadChildren: () => import('./pages_en/feedback/feedback.module').then(m => m.FeedbackModule) },
  { path:'rules_en',loadChildren: () => import('./pages_en/rules/rules.module').then(m => m.RulesModule) },
  { path:'signup_en',loadChildren: () => import('./pages_en/signup/signup.module').then(m => m.SignupModule) },
  { path:'signupSuccess_en', loadChildren: () => import('./pages_en/signup/signup-success/signup-success.module').then(m => m.SignupSuccessModule) },
  { path:'login_en', loadChildren: () => import('./pages_en/login/login.module').then(m => m.LoginModule)},
  { path:'register_en', loadChildren: () => import('./pages_en/register/register.module').then(m => m.RegisterModule) },
  { path:'forget_en', loadChildren: () => import('./pages_en/forget/forget.module').then(m => m.ForgetModule) },
  { path:'mydata_en', loadChildren: () => import('./pages_en/mydata/mydata.module').then(m => m.MydataModule) },
  { path:'account_en', loadChildren: () => import('./pages_en/account/account.module').then(m => m.AccountModule) },
  { path:'theme_en', loadChildren: () => import('./pages_en/theme/theme.module').then(m => m.ThemeModule)},
  { path:'submittedTheme_en', loadChildren: () => import('./pages_en/submitted-theme/submitted-theme.module').then(m => m.SubmittedThemeModule) },
  { path:'theme-edit_en',loadChildren: () => import('./pages_en/theme/theme-edit/theme-edit.module').then(m => m.ThemeEditModule) },
  { path:'speech_en/:id',loadChildren: () => import('./pages_en/speech/speech.module').then(m => m.SpeechModule) },
  { path:'live_en/:id/:name', loadChildren: () => import('./pages_en/live/live-detail/live-detail.module').then(m => m.LiveDetailModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="body">
  <!-- <nz-affix [nzOffsetTop]="0"> -->
    <div class="header">
      <div class="top">
        <div>
          <i class="iconfont icon-caidan" style="font-size: 30px;padding-left: 10px;color: #ffffff;" nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomLeft'"></i>
        </div>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable class="menus">
            <li nz-menu-item>首页</li>
            <li nz-menu-item routerLink="/guest">嘉宾</li>
            <li nz-menu-item routerLink="/agenda">议程</li>
            <li nz-menu-item routerLink="/sponsor">赞助商</li>
            <!-- <li nz-menu-item routerLink="/organizer">组织者</li> -->
            <li *ngIf="!isThemeEnd" nz-menu-item (click)="goUrl('/theme')">提交演讲</li>
            <li nz-menu-item routerLink="/cooperate">合作与招募</li>
            <li nz-menu-item routerLink="/accommodation">交通住宿</li>
            <li nz-menu-item (click)="toHref('https://marketing.csdn.net/p/6c7d485dbe5ea7de8e2b0a1f0f967573')">直播入口</li>
            <li nz-menu-item routerLink="/previous">往届</li>
            <!-- <a href="https://2020.postgresconf.cn/photos" target="_blank"><li nz-menu-item>大会图库</li></a> -->
            <!-- <li nz-menu-item routerLink="/en">EN</li> -->
        </ul>   
        </nz-dropdown-menu>
        <!-- <div>
          <span class="active">首页</span>
          <span routerLink="/guest">嘉宾</span>
          <span routerLink="/agenda">议程</span>
          <span routerLink="/sponsor">赞助商</span>
          <span (click)="goUrl('/theme')">提交演讲</span>
          <span routerLink="/cooperate">合作与招募</span>
          <span routerLink="/liveEntry">直播入口</span>
          <span routerLink="/previous">往届</span>
          <span><a href="https://2020.postgresconf.cn/photos" target="_blank">大会图库</a></span>
          <span routerLink="/en">EN</span>
        </div> -->
        <div class="user" *ngIf="userinfo == null" style="padding-right:15px;">
          <i class="iconfont icon-yonghutouxiang" style="margin-right: 8px;font-size: 20px;color: #FFFFFF;"></i>
          <span><a routerLink="/register">注册</a></span>
          <span style="margin: 0 8px;">|</span>
          <span><a routerLink="/login">登录</a></span>
        </div>
        <div class="user" *ngIf="userinfo != null">
          <span>{{userinfo.name}}</span>
          <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="padding-right: 10px;">
            <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
            <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
            <i class="iconfont icon-xiala xiala"></i>
          </div>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu
              style="font-size:14px;margin-top: -10px;">
              <li class="addli" nz-menu-item [routerLink]="['/signup']">
                <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>参会报名
              </li>
              <li class="addli" nz-menu-item [routerLink]="['/mydata']">
                <i class="iconfont icon-baoming" style="font-size: 20px;"></i>完善个人资料
              </li>
              <li class="addli" nz-menu-item [routerLink]="['/theme']" *ngIf="!isThemeEnd">
                <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>提交演讲话题
              </li>
              <li class="addli" nz-menu-item [routerLink]="['/submittedTheme']">
                <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>我提交的演讲
              </li>
              <li class="addli" nz-menu-item (click)="logout()">
                <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>退出登录
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        
      </div>
    </div>
  <!-- </nz-affix> -->
  <div>
    <div style="padding: 0 2%;text-align: center;">
      <img src="../../../assets/images/shouye-banner-mb.png" style="width: 100%;">
      <!-- <div style="width: 75vw;margin-top: 47px;height: 400px;"></div> -->
      <div class="sign-banner">
        <button class="sign-btn" (click)="isVisibleMiddle=true">参会报名</button>
        <!-- <div class="sign-text"><i class="iconfont icon-jiantouyou you-icon"></i>
          <i class="iconfont icon-jiantouyou you-icon" style="margin-right: 5px;"></i>报名通道已开启
        </div> -->
      </div>
      <div class="des"></div>
      <div class="item">
        <div class="item-title">
          <span>关于</span>
          <span class="test"> / ABOUT</span>
        </div>
        <div class="about-content">
            <div class="content">
              <div>数据已被列为第五大生产要素。数据的增长也为数据库产业带来机遇。根据中国信通院测算，预计到2025年，中国数据库市场总规模将达到688亿元，而开源数据库正成为技术与市场变革的新引擎。PostgreSQL（以下简称“PG”）是世界最强大的开源关系型数据库。基于PG二次开发，已经成为国产数据库实现突围、发展自主可控产品的重要途径。应用原生PG或基于PG的商业产品，也已经成为国内数据库用户替代Oracle的最佳选择之一。</div>
              <div style="margin-top: 10px;">开源生态建设既是帮助企业商业布局的利器，也是促进技术发展和推广的重要手段。中国PG分会（全称：中国开源软件推进联盟PG分会）在依托工信部中国开</div>
            </div>
            <div class="content">
              <div>源软件推进联盟，作为国内唯一的PG行业协会组织，承担着在国内推广和发展PG技术的职能。自2018年起，连续四年，中国PG分会面向国内外从业者，先后举办“PGOpen.CN2018”、“PostgresConf.CN2019”、“PGConf.Asia2020”、 “PGConf.Asia2021”四场国际性的PG年度大会，持续列为PG国际社区官网全球金标会议。</div>
              <div style="margin-top: 10px;">继往开来，由中国PG分会主办的“2022中国PG生态大会”，将以“协同共进”为主题，邀请专家学者、厂商和用户代表，就PG在行业和区域的推广与应用进行深入探讨。 同时，会上还将举行2022年度PG中国技术评选颁奖典礼。</div>
            </div>
        </div>
        <div class="about-circle">
          <div class="circle-item cir1">
            <div class="num">1500+</div>
            <div>全国参会者</div>
          </div>
          <div class="circle-item cir2">
            <div class="num">50+</div>
            <div>专家演讲</div>
          </div>
          <div class="circle-item cir1">
            <div class="num">4个</div>
            <div>精彩论坛</div>
          </div>
          <div class="circle-item cir2">
            <div class="num">3天</div>
            <div>线上直播</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="item">
    <div class="item-title">
      <span>特邀嘉宾</span>
      <span class="test"> / GUESTS</span>
    </div>
    <div class="guest-swiper">
      <i class="iconfont icon-zuo" (click)="carousel.pre()" title="上一页"></i>
      <nz-carousel class="swiper" [nzDots]="false" #carousel>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/jiabin/bruce.jpg">
            </div>
            <h1>Bruce Momjian</h1>
            <p>Enterprise DB公司副总裁<br/>
              PostgreSQL国际社区核心管理小组成员
              </p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/jiabin/lushouqun.jpg">
            </div>
            <h1>陆首群</h1>
            <p>中国开源软件推进联盟名誉主席<br/>
              原国务院信息办常务副主任</p>
          </div>
        </div>
        <div nz-carousel-content>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/jiabin/liupeng.jpg">
            </div>
            <h1>刘澎</h1>
            <p>工信部中国开源软件推进联盟副主席<br/>
              兼秘书长</p>
          </div>
          <div class="card">
            <div class="photo">
              <img src="../../../assets/images/jiabin/dingzhiming.jpg">
            </div>
            <h1>丁治明</h1>
            <p>中科院软件所研究员</p>
          </div>
        </div>
      </nz-carousel>
      <i class="iconfont icon-you" (click)="carousel.next()" title="下一页"></i>
    </div>
  </div>
  <div class="item">
    <div class="item-title">
      <span>大会日程</span>
      <span class="test"> / AGENDA</span>
    </div>
    <div class="agenda" *ngFor="let meeting of meetingList;index as i">
      <div class="agenda-content">
        <div class="agenda-content-item" *ngFor="let list of meeting.specialshow;index as sort">
          <div class="content-left">
            <img  src="../../../assets/images/shouye/graph-01.jpg" alt="" style="width: 100%;" *ngIf="sort == 0 && i == 0">
            <img  src="../../../assets/images/shouye/graph-02.jpg" alt="" style="width: 100%;" *ngIf="sort == 0 && i == 1">
            <img  src="../../../assets/images/shouye/graph-03.jpg" alt="" style="width: 100%;" *ngIf="sort == 1 && i == 1">
            <img  src="../../../assets/images/shouye/graph-04.jpg" alt="" style="width: 100%;" *ngIf="sort == 0 && i == 2">
            <img  src="../../../assets/images/shouye/graph-05.jpg" alt="" style="width: 100%;" *ngIf="sort == 1 && i == 2">
          </div>
          <div class="content-right">
            <div class="right-item right-one">
              <div class="right-one-time">{{list.date}}<span style="font-size: 20px;font-weight: 500;margin-top: 8px;"> {{list.time_type==1?'AM':'PM'}}</span></div>
              <div class="right-one-address">{{list.format}}</div>
            </div>
            <div class="right-item" style="display: flex;">
              <div style="text-align: left;" class="right-item-intro">
                <div style="font-weight: bold;font-size: 1rem;">{{list.name}}</div>
                <div>/ {{list.name_en}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div style="padding: 0 2%;">
      <div class="item">
        <div class="item-title">
          <span>合作</span>
          <span class="test"> / COOPERATE</span>
        </div>
        <div style="text-align: left;padding: 0 15px;">
          <div class="organize">组织者</div>
          <div class="org-img">
            <img src="../../../assets/images/cooperation/1.png">
            <img src="../../../assets/images/cooperation/zhongkeyuan.png" style="width: 180px;">
          </div>
          <div class="organize">社区合作伙伴</div>
          <div class="org-img">
            <a href="https://pgfans.cn/" target="_blank"><img src="../../../assets/images/pgfans.jpg"></a>
            <a href="https://www.oschina.net/" target="_blank"><img src="../../../assets/images/oschina.jpg"></a>
            <a href="https://cn.greenplum.org/" target="_blank"><img src="../../../assets/images/greenplum.jpg"></a>
            <a href="https://www.modb.pro/" target="_blank"><img src="../../../assets/images/modb.jpg"></a>
            <a href="http://mongoing.com/" target="_blank"><img src="../../../assets/images/mongo.png"></a>
            <a href="https://www.csdn.net/" target="_blank"><img src="../../../assets/images/logo-csdn.png"></a>
            <a href="https://segmentfault.com/" target="_blank"><img src="../../../assets/images/logo-sf.png"></a>
            <a href="" target="_blank"><img src="../../../assets/images/logo-hdj.png"></a>
            <a href="https://dbaplus.cn/" target="_blank"><img src="../../../assets/images/dba.jpg"></a>
          </div>

          <div class="organize">媒体合作伙伴</div>
          <div class="org-img" style="margin-bottom: 30px;">
            <img src="../../../assets/images/media1.png">
            <img src="../../../assets/images/media2.png">
            <img src="../../../assets/images/media3.png">
            <img src="../../../assets/images/media4.png">
            <img src="../../../assets/images/media5.png">
            <img src="../../../assets/images/media6.png">
            <img src="../../../assets/images/media7.png">
            <img src="../../../assets/images/media8.png">
            <img src="../../../assets/images/media9.png">
          </div>
        </div>
      </div>
      <div class="item speech-item">
        <div class="item-title">
          <span>演讲征集</span>
          <span class="test"> / CFP</span>
        </div>
        <div class="speech-content"> 
          <div class="speech-content-item">
            <div>
              <p>本届大会的演讲围绕“原生PG”和“生态产品”两方面，为每位嘉宾提供30分钟以内的分享时段。如果您想围绕“原生PG”进行分享，请点击下方提交申请。请您先提交演讲主题，截止日期为1月10日，剩余{{timeDay1}}天。</p>
              <p>如果您想围绕“产品”进行分享，请联系大会负责人（15863186550，微信同手机号）。</p>
            </div>
            <button *ngIf="!isThemeEnd" class="b-btn-orange submit-btn" (click)="goUrl('/theme')" style="align-self: end">提交演讲<i class="iconfont icon-jiantou_xiangyou" style="font-size: 18px;margin: 10px;"></i></button>
            <button *ngIf="isThemeEnd" class="g-btn submit-btn" disabled style="width: 200px;align-self: end">演讲提交已截止</button>
          </div>
          <div class="speech-content-item" style="margin-top: 10%">
            <div>
              <p>组委会将于1月20日前完成演讲评审，如您届时审核通过，请下载并使用大会统一的PPT模板。 </p>
              <p>PPT提交截止到1月31日，剩余{{timeDay}}天。</p>
            </div>
            <!-- *ngIf="!isEnd" -->
            <a style="align-self: end" href="../../../assets/PostgresConf.CN 2022 PPT模板.pptx" download="PostgresConf.CN 2022 PPT模板.pptx">
              <button  class="b-btn-blue submit-btn">下载PPT模板<i class="iconfont icon-xiazai" style="font-size: 18px;margin: 10px;"></i></button>
            </a>
          </div>
        </div>
      </div>
      
      <div class="item" style="margin-top: 60px;">
        <div class="item-title">
          <span>2021 PG中国技术评选征集</span>
          <span class="test"> / AWARD</span>
        </div>
        <div style="padding:10px 15px;line-height: 22px;font-size: 14px;">
          这一年，PostgreSQL在中国正以前所未有的速度向前发展。我们见证了中国数据库市场的行业变革，目睹了一批技术领袖的崛起，同时亲历了数百家生态企业创新成长。<br><br>
          我们将再次举办<span style="font-weight: bold;">“PostgreSQL中国年度技术评选”</span>，并在本届大会上隆重发布。<!-- 九大奖项，九鼎之尊，立于华夏。 --><br><br>
        </div>
        <div class="award">
          <!-- <div class="award-item">
            <div><span class="category">人物类</span> (2个奖项)</div>
            <div class="prize">
              <div class="prize-item">
                <div>2021 PostgreSQL中国</div>
                <div style="font-weight: bold;">杰出贡献者</div>
                <div style="margin-top: 15px;">
                  <button class="y-btn num-btn">1-3席</button>
                  <i class="iconfont icon-wode1 prize-icon"></i>
                </div>
              </div>
              <div class="prize-item">
                <div>2021 PostgreSQL中国</div>
                <div style="font-weight: bold;">优秀创新人物</div>
                <div style="margin-top: 15px;">
                  <button class="y-btn num-btn">1-3席</button>
                  <i class="iconfont icon-wode1 prize-icon"></i>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div>
          <a href="../../../assets/2021 PostgreSQL中国技术评选-申报说明.docx" download="2021 PostgreSQL中国技术评选-申报说明.docx">
            <button class="b-btn submit-btn">申报说明与模板</button>    
          </a>
        </div> -->
      </div>
     
    </div>
  </div>
  <nz-modal
  [(nzVisible)]="isVisibleMiddle"
  nzCentered
  (nzOnCancel)="handleCancelMiddle()"
  (nzOnOk)="handleOkMiddle()"
  [nzTitle]="modalTitle"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
  *ngIf="!isDelayVisible"
  >
  <ng-template #modalTitle id="sign-modal">
    <img src="../../../assets/images/neiye/graph-signup.png" alt="" style="width: 100%;">
  </ng-template>
        <ng-template #modalContent>
          <div class="big-title" style="font-size: 20px;font-family: Helvetica Neue LT Pro;">PostgresConf.CN <span style="color: #fff;-webkit-text-stroke: 2px #000;font-family: Helvetica Neue LT Pro;">2022</span></div>
          <div>请选择参会方式</div>
          <div style="text-align: center">
            <button class="b-btn-orange submit-btn" (click)="goUrl('/signup')" style="margin-right: 20px;">线下参会</button>
            <a (click)="toHref('https://marketing.csdn.net/p/6c7d485dbe5ea7de8e2b0a1f0f967573')" target="_blank"><button class="b-btn-blue submit-btn" (click)="isVisibleMiddle=true">线上参会</button></a>
          </div>
        </ng-template>
  
        <ng-template #modalFooter></ng-template>
  </nz-modal>

  <nz-modal
[(nzVisible)]="isDelayVisible"
nzCentered
(nzOnCancel)="handleDelayCancelMiddle()"
(nzOnOk)="handleDelayOkMiddle()"
[nzTitle]="modalTitle"
[nzContent]="modalContent"
[nzFooter]="modalFooter"
key="md-2"
*ngIf="isDelayVisible"
>
<ng-template #modalTitle id="delay-modal">
  <img src="../../../assets/images/neiye/graph-signup.png" alt="" style="width: 100%;">
</ng-template>
      <ng-template #modalContent>
        <div class="big-title" style="font-size: 30px;font-family: Helvetica Neue LT Pro;">PostgresConf.CN <span style="font-family: Akira Outline;-webkit-text-stroke: 1px #000;">2022</span></div>
        <div style="font-size: 20px;font-weight: bold;">延期通知</div>
        <div style="text-align: center">
          <a href="../../../assets/2022中国PostgreSQL数据库生态大会-会议延期通知.pdf" download="2022中国PostgreSQL数据库生态大会-会议延期通知.pdf"><button class="b-btn-orange submit-btn" style="margin-right: 20px;">延期公告下载</button></a>
          
        </div>
      </ng-template>

      <ng-template #modalFooter></ng-template>
</nz-modal>
</div>
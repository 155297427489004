import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/service/storage.service';
import { TimeXService } from 'src/app/service/TimeX.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isThemeEnd = false;
  constructor(
    private router: Router,
    private local: StorageService,
    private timex: TimeXService,
  ) { 
    timex.getTimeX();
    this.isThemeEnd = timex.isThemeEnd;
  }

  ngOnInit() {
  }

  changeUrl(url){
    if (typeof window !== 'undefined') {
      if(this.local.get('userinfo')==null){
        this.router.navigate(['/login'],{queryParams:{type: url}});
      }else{
        this.router.navigate([url]);
      }
    }
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeXService {
  timeDay1 = 0;
  timeDay = 0;
  isThemeEnd = false;
  isEnd = false;

constructor() { }

  // 计算时间差
  getTimeX(time='',time2='') {
    var endTime = new Date('2023/1/10 23:59:59').getTime() / 1000 - new Date().getTime() / 1000; 
    if(time!='') {
      endTime = new Date(time).getTime() / 1000 - new Date().getTime() / 1000; 
    }
    var endTime2 = new Date('2023/1/10 23:59:59').getTime() / 1000 - new Date().getTime() / 1000;
    if(time2!='') {
      endTime = new Date(time2).getTime() / 1000 - new Date().getTime() / 1000; 
    }
    var timeDay1 = Math.ceil(endTime2 / 60 / 60 / 24);
    var timeDay = Math.ceil(endTime / 60 / 60 / 24);
    if(timeDay1<=0){
      this.timeDay1 = 0;
      this.isThemeEnd = true;
    }else{
      this.timeDay1 = timeDay1;
      this.isThemeEnd = false;
    }
    
    if(timeDay<=0){
      this.timeDay = 0;
      this.isEnd = true;
    }else{
      this.timeDay = timeDay;
      this.isEnd = false;
    }
  }

}

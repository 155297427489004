import { Component } from '@angular/core';
import { HttpService } from './service/http.service';
import { Md5 } from "ts-md5";
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { StorageService } from './service/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileService } from './service/profile.service';
import { WechatService } from './service/wechat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  thisUrl = '/';
  userinfo:any;

  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private local: StorageService,
    private modal: NzModalService,
    private profile: ProfileService,
    private wechat: WechatService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        var thisUrl = event.url.split('?')[0].split('/')[1];
        this.thisUrl = '/'+ thisUrl;
        if (typeof window !== 'undefined') {
          if(local.get('userinfo')!=null){
            this.userinfo = local.get('userinfo');
            this.checkSession();
            this.profile.getProfile(this.userinfo.id,this.userinfo.sessionid, 2);
          }
          if (window.sessionStorage.getItem('firstEntryUrl')==null) {
            window.sessionStorage.setItem('firstEntryUrl', window.location.origin + this.thisUrl);
          } 
        setTimeout(()=>{
          var img = 'https://admin.postgresconf.cn/img/logo.jpg';
          var title = 'PostgresConf.CN & PGConf.Asia 2021';   
          if(this.thisUrl=='/en'||this.thisUrl=='/sponsor_en'||this.thisUrl=='/organizer_en'||this.thisUrl=='/cooperate_en'||this.thisUrl=='/agenda_en'||this.thisUrl=='/liveEntry_en'||
          this.thisUrl=='/exhibition_en'||this.thisUrl=='/previous_en'||this.thisUrl=='/feedback_en'||this.thisUrl=='/collect_en'||this.thisUrl=='/rules_en'||this.thisUrl=='/speech_en'||
          this.thisUrl=='/signup_en'||this.thisUrl=='/signupSuccess_en'||this.thisUrl=='/login_en'||this.thisUrl=='/register_en'||this.thisUrl=='/forget_en'||
          this.thisUrl=='/mydata_en'||this.thisUrl=='/account_en'||this.thisUrl=='/theme_en'||this.thisUrl=='/submittedTheme_en'||this.thisUrl=='/theme-edit_en'){
            var desc = 'On December 14-17, 2021,the 2021 PostgreSQL Asia Technology Conference (PostgresConf.CN & PGConf.Asia2021) will open soon.';
          }else{
            var desc = '2021年12月14-17日，2021年度PostgreSQL亚洲技术大会（PostgresConf.CN & PGConf.Asia2021）即将重磅开启。';
          }
          var href = window.location.href;
          this.wechat.initWxShareConfig(title, desc,href, img);
        },200);
      }
    
      }
    });
  
  }



    /**
   * 判断登录是否过期
   */
  checkSession() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|checksession');
    var api = 'user/user/checkSession';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id': this.userinfo.id,
      'language_type':2,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      //  console.log(result);
      if (result.code != "200") {
        this.local.remove('userinfo');
        this.local.set('currentMetting', 2);
        window.location.reload();
      } 
    })
  }


}

<div>
  <div class="girdle">
    <img src="../../../assets/images/bottom-graph-mobile.jpg">
  </div>
  <div class="bottom">
    <img src="../../../assets/images/logo-bottom.jpg">
    <div class="contact">
      <span style="color: #333333;font-family: Microsoft YaHei UI;">Cooperative : </span>+86 13001748183<span style="color: #8B8A8A;font-family: Microsoft YaHei UI;">(wechat)</span><br>
      <span style="color: #333333;font-family: Microsoft YaHei UI;">Email : </span>meeting@postgresqlchina.com<br>
      Skype : grant.zhou1<br>
    </div>
    <div style="text-align:center;">
      <img src="../../../assets/images/qrcode.jpg" style="width: 70%;">
      <div>Keep abreast of the meeting</div>
    </div>
  </div>
  <hr>
  <div class="foot">
    <div><!--<a routerLink="/guest">Guest</a>|--><a routerLink="/agenda_en">Schedule</a>/<a routerLink="/sponsor_en">Sponsor</a>/<a routerLink="/organizer_en">Organizer</a>
      <a *ngIf="!isThemeEnd" (click)="changeUrl('/theme_en')">Submit speech</a>/<a (click)="changeUrl('/signup_en')">Sign up</a>/<br>
      <a routerLink="cooperate_en">Cooperate & Recruit</a>/<a routerLink="/previous_en">Previous</a>/<a routerLink="/feedback_en">Feedback</a>/
      <a routerLink="/rules_en">Code of Conduct</a></div>
    <div class="copyright">© 2021 China PostgreSQL Association</div>
    <div class="copyright"><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #999999;font-size: 14px;">鲁ICP备09010331号-9</a></div>
  </div>
</div>

<div>
  <div class="girdle">
    <img src="../../../assets/images/bottom-graph-mobile.jpg">
  </div>
  <div class="bottom">
    © 2021 China PostgreSQL Association
    <div style="line-height: 34px;"><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #999999;font-size: 14px;">鲁ICP备09010331号-9</a></div>
  </div>
</div>


<nz-affix [nzOffsetTop]="0">
    <div class="bg">
        <div class="top">
            <div>
              <i class="iconfont icon-caidan" style="font-size: 30px;padding-left: 10px;color: #333333;" nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomLeft'"></i>
            </div>
            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable class="menus">
                    <li nz-menu-item routerLink="/">首页</li>
                    <li nz-menu-item routerLink="/guest">嘉宾</li>
                    <li nz-menu-item routerLink="/agenda">议程</li>
                    <li nz-menu-item routerLink="/sponsor">赞助商</li>
                    <!-- <li nz-menu-item routerLink="/organizer">组织者</li> -->
                    <!-- <li *ngIf="!isThemeEnd" nz-menu-item (click)="navActive('/theme')">提交演讲</li> -->
                    <li nz-menu-item routerLink="/cooperate">合作与招募</li>
                    <li nz-menu-item routerLink="/accommodation">交通住宿</li>
                    <li nz-menu-item (click)="toHref('https://marketing.csdn.net/p/6c7d485dbe5ea7de8e2b0a1f0f967573')">直播入口</li>
                    <li nz-menu-item routerLink="/previous">往届</li>
                    <!-- <a href="https://2020.postgresconf.cn/photos" target="_blank"><li nz-menu-item>大会图库</li></a> -->
                    <!-- <li nz-menu-item routerLink="/en">EN</li> -->
                </ul>    
            </nz-dropdown-menu>
            <div class="user" *ngIf="userinfo == null" style="padding-right:15px;">
                <i class="iconfont icon-yonghutouxiang" style="margin-right: 8px;font-size: 20px;"></i>
                <span><a routerLink="/register">注册</a></span>
                <span style="margin: 0 8px;">|</span>
                <span><a routerLink="/login">登录</a></span>
            </div>
            <div class="user" *ngIf="userinfo != null">
                <span>{{userinfo.name}}</span>
                <div  nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="padding-right:15px;">
                    <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
                    <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
                    <i class="iconfont icon-xiala xiala"></i>
                </div>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu style="font-size:14px;margin-top: -15px;">
                        <li class="addli" nz-menu-item [routerLink]="['/signup']">
                            <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>参会报名
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/mydata']">
                            <i class="iconfont icon-baoming" style="font-size: 20px;"></i>完善个人资料
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/theme']" *ngIf="!isThemeEnd">
                            <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>提交演讲话题
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/submittedTheme']">
                            <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>我提交的演讲
                        </li>
                        <li class="addli" nz-menu-item (click)="logout()">
                            <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>退出登录
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</nz-affix>